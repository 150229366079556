header{
    height: 100vh;
    padding-top: 8rem;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    /* place-items: center; */
    overflow: hidden;
}
.header-container{
    text-align: center;
    height: 100%;
    position: relative;
    
}

/* jhkkgkg? */

/* Base */
/* body {
    padding: 0;
    margin: 0;
    background-color: #000!important;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  } */
  .animation {
    color: hsl(0, 0%, 28%);
    font-size: 50px!important;
    font-weight: bold!important;
    font-family: monospace;
    letter-spacing: 7px!important;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .animation {
    padding: 10px;
    background: linear-gradient(to right, hsl(0, 0%, 30%) 0, hsl(0, 0%, 100%) 10%, hsl(0, 0%, 30%) 20%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
  }
  
  @keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 600px;
    }
    100% {
      background-position: 600px;
    }
  }

/* CTA  */

.cta{
    margin-top: 3.5rem;
    display: grid;
    place-items: center;
    /* align-content: center; */
    gap: 1.2rem;
    /* justify-content: center; */
    /* background-color: red; */
    /* align-self: center;
    place-self: center; */
}

 /* HEADER  SOCIALS   */

 .header-socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
 }
 .header-socials::after{
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
 }

/* ME   */

.me {
    background: linear-gradient(var(--color-primary),
    transparent);
    width: 20rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 10rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}
.me-img{
 height: 15rem;
 width: 8rem;
}

.scroll-down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;
}

/* MEDIA QUERRY (TAB)  */

@media screen and (max-width:1024px) {
    header {
        /* height: 100vh; */
        padding-top: 14rem;
    }
}

/* MEDIA QUERRY (MOBILE)  */

@media screen and (max-width:600px) {
    header{
        height: 100vh;
        /* display: grid;
        place-items: center; */
        /* background-color: yellow; */

    }
    .header-socials, 
    .scroll-down {
        display: none;
    }
    .animation{
        font-size: 26px! important;
        letter-spacing: 5px;
    }
    .cta{
      margin-top: 5.5rem;
    }
}