.portfolio-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}
.portfolio-item{
    background: var(--color-bg-varient);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.portfolio-item:hover{
    border-color: var(--color-primary-varient);
    background: transparent;
}
.portfolio-item-img{
    border-radius: 1.5rem;
    overflow: hidden;
}
.portfolio-item h3{
    margin: 1.2rem 0 2rem;
}
.portfolio-item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}
/* MEDIA QUERRY (TAB)  */

@media screen and (max-width:1024px) {
    .portfolio-container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* MEDIA QUERRY (MOBILE)  */

@media screen and (max-width:600px) {
    .portfolio-container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    
}