.container.contact-container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}
.contact-options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.contact-option{
    background: var(--color-bg-varient);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}
.contact-option:hover{
    background: transparent;
    border-color: var(--color-primary-varient);
}
.contact-option-icon{
    font-size: 1.5rem;
    margin: 0.5rem;
}
.contact-option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}
/* form  */
form{
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}
input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-varient);
    resize: none;
    color: var(--color-white);
}

/* MEDIA QUERRY (TAB)  */

@media screen and (max-width:1024px) {
    .container.contact-container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* MEDIA QUERRY (MOBILE)  */

@media screen and (max-width:600px) {
    .container.contact-container{
        width: var(--container-width-sm);
    }
    
}