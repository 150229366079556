.container.testimonials-container{
    width: 40%;
    height: 50%;
    /* padding: 4rem; */
}
.client-avatar{
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-varient);
}
.testimonial{
    background: var(--color-bg-varient);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}
.client-review{
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

/* MEDIA QUERRY (TAB)  */

@media screen and (max-width:1024px) {
    .container.testimonials-container{
        width: 60%;
    }
}

/* MEDIA QUERRY (MOBILE)  */

@media screen and (max-width:600px) {
    .container.testimonials-container{
        width: var(--container-width-sm);
    }
    .client-review{
        width: var(--container-width-sm);
    }
    
}